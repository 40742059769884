import React, { useState } from 'react';
import { bool, func, oneOfType, string } from 'prop-types';
import truncate from 'lodash/truncate';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { ensureUser, ensureCurrentUser, mapFieldsOptionsToLabel } from '../../../util/data';
import { USER_TYPE_DOCTOR, propTypes } from '../../../util/types';

import { AvatarLarge, NamedLink, InlineTextButton, H3, H6 } from '../../../components';
import { IoStar } from 'react-icons/io5';
import { IoIosStarHalf } from 'react-icons/io';

import css from './UserCard.module.css';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the author section of the ListingPage.
const BIO_COLLAPSED_LENGTH = 170;

const truncated = s => {
  return truncate(s, {
    length: BIO_COLLAPSED_LENGTH,

    // Allow truncated text end only in specific characters. This will
    // make the truncated text shorter than the length if the original
    // text has to be shortened and the substring ends in a separator.
    //
    // This ensures that the final text doesn't get cut in the middle
    // of a word.
    separator: /\s|,|\.|:|;/,
    omission: '…',
  });
};

const ExpandableBio = props => {
  const [expand, setExpand] = useState(false);
  const { className, bio } = props;
  const truncatedBio = truncated(bio);

  const handleShowMoreClick = () => {
    setExpand(true);
  };
  const showMore = (
    <InlineTextButton rootClassName={css.showMore} onClick={handleShowMoreClick}>
      <FormattedMessage id="UserCard.showFullBioLink" />
    </InlineTextButton>
  );
  return (
    <p className={className}>
      {expand ? bio : truncatedBio}
      {bio !== truncatedBio && !expand ? showMore : null}
    </p>
  );
};

ExpandableBio.defaultProps = { className: null };

ExpandableBio.propTypes = {
  className: string,
  bio: string.isRequired,
};

const UserCard = props => {
  const {
    rootClassName,
    className,
    user,
    currentUser,
    onContactUser,
    showContact,
    listing,
    isDoctor,
    canDoctorApply,
    applyQueryInProgress,
    applyQueryError,
    currentUserRole,
    listingConfig,
    isSameRole,
    hasMatchingListing,
  } = props;

  const specialityEnumOptions = listingConfig?.listingFields?.find(lc => lc.key === 'speciality')
    ?.enumOptions;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
  const disableContact = !canDoctorApply && !isDoctor && currentUserRole === USER_TYPE_DOCTOR;
  const { publicData } = listing?.attributes || {};
  const { education, speciality, sub_speciality, rating = 0, reviews = 0 } = publicData || {};

  // Mapping specialities
  const mappedSpeciality = mapFieldsOptionsToLabel(specialityEnumOptions, speciality);

  // Rating Start
  const totalRatings = reviews;
  const avgRating = rating;
  const starArray = [1, 2, 3, 4, 5];
  // Rating End

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const { displayName, bio } = ensuredUser.attributes.profile;

  const handleContactUserClick = () => {
    onContactUser(user);
  };

  const hasBio = !!bio;
  const classes = classNames(rootClassName || css.root, className);
  const linkClasses = classNames(css.links, {
    [css.withBioMissingAbove]: !hasBio,
  });

  const separator =
    isCurrentUser || !showContact ? null : <span className={css.linkSeparator}>•</span>;

  const notAvailableMsg = <FormattedMessage id="UserCard.notAvailableMsg" />;

  const contact = showContact ? (
    <div>
      <button
        className={css.contact}
        onClick={handleContactUserClick}
        disabled={hasMatchingListing || isSameRole}
      >
        {hasMatchingListing ? (
          'Already applied'
        ) : (
          <FormattedMessage id="UserCard.contactUser" values={{ isDoctor: `${isDoctor}` }} />
        )}
      </button>
      {isSameRole ? <p className={css.notAllowed}>You are not allowed</p> : null}
    </div>
  ) : null;

  const editProfileMobile = (
    <span className={css.editProfileMobile}>
      <span className={css.linkSeparator}>•</span>
      <NamedLink name="ProfileSettingsPage">
        <FormattedMessage id="ListingPage.editProfileLink" />
      </NamedLink>
    </span>
  );

  const editProfileDesktop = isCurrentUser ? (
    <NamedLink className={css.editProfileDesktop} name="ProfileSettingsPage">
      <FormattedMessage id="ListingPage.editProfileLink" />
    </NamedLink>
  ) : null;

  // const links = ensuredUser.id ? (
  //   <p className={linkClasses}>
  //     <NamedLink className={css.link} name="ProfilePage" params={{ id: ensuredUser.id.uuid }}>
  //       <FormattedMessage id="UserCard.viewProfileLink" />
  //     </NamedLink>
  //     {separator}
  //     {isCurrentUser ? editProfileMobile : contact}
  //   </p>
  // ) : null;

  const links = !isCurrentUser ? contact : null;
  const educationMaybe = !!education ? <div className={css.education}>{education}</div> : null;
  const specialitiesMaybe =
  !!mappedSpeciality && isDoctor ? <div className={css.specialities}>{mappedSpeciality?.map((st)=>st.label)}</div> : null;
  
  

  const showStarsByRatings = (ratingPeruser = 0, totalRatings, starArray) => {
    const filledStars = Math.floor(ratingPeruser);
    const hasHalfStar = ratingPeruser % 1 !== 0;

    return (
      <div className={css.ratingContent}>
        {starArray.map(star => {
          return star === filledStars + 1 && hasHalfStar ? (
            <IoIosStarHalf className={css.starIconGold} />
          ) : (
            <IoStar
              className={star <= filledStars ? css.starIconGold : css.starIconGrey}
              key={star}
            />
          );
        })}
        <H6 className={css.ratingText}>
          {ratingPeruser.toFixed(1)} {`(${totalRatings} Review${totalRatings !== 1 ? 's' : ''})`}
        </H6>
      </div>
    );
  };

  return (
    <div className={classes}>
      <div className={css.content}>
        <AvatarLarge className={css.avatar} user={user} />
        <div className={css.info}>
          <H3 className={css.doctorName}>{displayName}</H3>
          {educationMaybe}
          {specialitiesMaybe}
          {isDoctor ? showStarsByRatings(avgRating, totalRatings, starArray) : null}
          {links}
        </div>
      </div>
    </div>
  );
};

UserCard.defaultProps = {
  rootClassName: null,
  className: null,
  user: null,
  currentUser: null,
  showContact: true,
};

UserCard.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
  onContactUser: func.isRequired,
  showContact: bool,
  listing: propTypes.listing.isRequired,
};

export default UserCard;
